import Image from 'next/image';
import styles from './SimpleLayout.module.scss';
import {
  CartBucket,
  FaqsIcon,
  Filter,
  GroupUsersIcon,
  Logo,
  LogoutIcon,
  OrdersIcon,
  SettingsIcon,
  UserIcon,
  BackgroundImage,
} from 'assets/img';
import { StoreContext } from 'pages/_app';
import { useEffect, useContext, Fragment, useState } from 'react';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { Disclosure, Dialog, Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import Link from 'next/link';
import pluralize from 'pluralize';
import { useRouter } from 'next/router';
import ConfirmationModal from 'components/ConfirmationModal';
import LoggedInAvatar from 'components/Shared/LoggedInAvatar';
import classNames from 'classnames';
import Cookies from 'js-cookie';
import { removeKey } from 'utils/helpers/localStorage';
import { setCookies } from 'utils/cookies/cookieHandler';

function SimpleLayout() {
  const store = useContext(StoreContext);
  const userStore = toJS(store?.userStore);
  const cartStore = toJS(store?.cartStore);
  const restaurantStore = toJS(store?.restaurantStore);
  const { activeMenuItemTagsLength } = restaurantStore;
  const [isDrawerOpen, setDrawer] = useState(false);
  const [isTopRightDrawerOpen, setTopRightDrawer] = useState(false);
  const [logoutAlert, setLogoutAlert] = useState(null);
  let { cart } = cartStore;
  let { currentUser, isAdmin, isUncateringUser, employeeID, uncateringEmpID, isTeamAdmin } =
    userStore;
  const router = useRouter();

  useEffect(() => {}, []);

  const logout = () => {
    store.userStore.setLoader(true);

    setTimeout(() => {
      store.userStore.setLoader(false);
      setDrawer(false);

      setTimeout(() => {
        setLogoutAlert(null);
      }, 1000);

      const isRestaurantDetailsPage = router?.route == '/restaurant/[id]/[name]';

      store.userStore.clearUser(isRestaurantDetailsPage);

      store.cartStore.resetCart();

      //remove auth-token from localStorage
      removeKey('auth_token');
      store.userStore?.setIsUserLogin(false);
      store.userStore?.resetIDs();

      const isOrderDetailsPage = router?.route == '/order_confirmation/[slug]';

      if (isOrderDetailsPage) {
        router.push('/');
      } else {
        router.reload();
      }
    }, 1000);
  };

  const onLoginPressMobile = () => {
    setDrawer(false);

    setTimeout(() => {
      store.userStore.setLoginModal(true);
    }, 1000);
  };

  const onPressLogout = isMobile => {
    setTopRightDrawer(false);

    if (isMobile) {
      setDrawer(false);

      setTimeout(() => {
        setLogoutAlert(true);
      }, 1000);
    } else {
      setLogoutAlert(true);
    }
  };

  const onClickCancel = () => {
    setLogoutAlert(null);
  };

  const handleAdminLogin = () => {
    setCookies(employeeID, currentUser);
    window.location.href = process?.env?.NEXT_PUBLIC_APP_ADMIN_PANEL_LINK;
  };

  const handleUserAccountLogin = () => {
    setCookies(employeeID, currentUser);
    setCookies(uncateringEmpID, currentUser);
    window.location.href = process?.env?.NEXT_PUBLIC_APP_UNCATERING_APP_URL;
  };

  return (
    <>
      <Disclosure
        as="nav"
        className="bg-white navbar-nav md:pb-5 mx-auto sticky top-0 md:static z-50 border-background border-b-2"
        style={{ maxWidth: '1440px' }}>
        {({ open }) => (
          <>
            <div className="mx-auto max-w-full p-2 md:px-8">
              <div className="relative flex h-16 items-center justify-between">
                <div className="absolute z-10 inset-y-0 flex w-full items-center md:hidden">
                  {/* Mobile menu button*/}
                  <div className="flex w-full justify-between items-center sm:justify-end">
                    <div>
                      {router?.route == '/restaurant/[id]/[name]' ? (
                        <div
                          onClick={() => store?.restaurantStore?.setTagsFilterModal(true)}
                          className="flex sm:hidden items-center justify-center bg-background mr-2 px-5 py-4 h-12 w-16 rounded-lg">
                          <div>
                            <Image
                              src={Filter}
                              width={16}
                              height={16}
                              layout="fixed"
                              className="bg-background"
                              alt="filter-small"
                            />
                          </div>

                          {activeMenuItemTagsLength > 0 && (
                            <span className="ml-2 text-md">{activeMenuItemTagsLength}</span>
                          )}
                        </div>
                      ) : (
                        <Link href={'/'}>
                          <button className="flex btn-style sm:hidden p-2 flex-shrink-0 items-center">
                            <Image
                              src={Logo}
                              className="bg-white"
                              alt="Logo-hungerhub"
                              layout="fixed"
                              width={'106px'}
                              height={'39px'}
                            />
                          </button>
                        </Link>
                      )}
                    </div>

                    <div className="flex">
                      {(router?.route == '/restaurant/[id]/[name]' || router?.route == '/') && (
                        <div
                          onClick={() => store?.cartStore?.setCartMobile(true)}
                          className="flex items-center justify-center bg-background mr-2 px-5 py-2 rounded-lg h-12 m-w-[64px]">
                          <Image
                            src={CartBucket}
                            className="bg-background"
                            width={16}
                            height={16}
                            layout="fixed"
                            alt="cart-icon"
                          />

                          {cart?.items?.length > 0 && (
                            <span className="flex text-sm text-primary-black pl-2 font-inter-medium">
                              {cart?.items?.length}
                              <span className="hidden sm:flex ml-1">
                                {pluralize('Item', cart?.items?.length)}
                              </span>
                            </span>
                          )}
                        </div>
                      )}

                      <button
                        aria-label="menu-button"
                        onClick={() => setDrawer(true)}
                        className="inline-flex btn-style items-center btn-style justify-center rounded-md p-2 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                        <FontAwesomeIcon
                          width={22}
                          height={22}
                          className="ml-2"
                          icon={faBars}
                          color={'#2D3958'}
                          size="2x"
                        />
                      </button>
                    </div>
                  </div>
                </div>

                <Link href={'/'}>
                  <button className="hidden sm:flex flex-shrink-0 items-center sm:ml-2 md:ml-0">
                    <Image
                      src={Logo}
                      className=""
                      alt="Logo-hungerhub"
                      layout="fixed"
                      width={'150px'}
                      height={'55px'}
                    />
                  </button>
                </Link>

                <div className="hidden md:flex flex-1 flex-wrap items-center justify-center sm:items-stretch sm:justify-start">
                  {/* sm:ml-6 sm:block */}
                  <div className="flex items-center flex-wrap px-6 lg:px-16">
                    <div className="flex items-center space-x-10">
                      <div className={`${styles.navItem} group inline-block`}>
                        <button
                          className={`outline-none focus:outline-none cursor-pointer py-1 rounded-sm flex items-center`}>
                          <span className={`mx-0`}>FOR OFFICES •</span>
                        </button>

                        <ul className="bg-white z-50 shadow-md rounded-sm transform scale-0 group-hover:scale-100 absolute transition duration-150 ease-in-out origin-top min-w-32">
                          <li className="rounded-sm relative cursor-pointer py-2">
                            <a
                              className={styles.newDropdownItem}
                              href={`${process.env.NEXT_PUBLIC_MARKETING_SITE_URL}/uncatering`}>
                              Uncatering
                            </a>

                            <a className={styles.newDropdownItem} href="/">
                              On Demand Catering
                            </a>

                            <a
                              className={styles.newDropdownItem}
                              href={`${process.env.NEXT_PUBLIC_MARKETING_SITE_URL}/managed-catering`}>
                              Managed Catering + Events
                            </a>
                          </li>
                        </ul>
                      </div>

                      <a
                        className={styles.navItem}
                        href={`${process.env.NEXT_PUBLIC_MARKETING_SITE_URL}/uncatering`}>
                        FOR RESTAURANTS
                      </a>
                    </div>
                  </div>
                </div>

                <div className="hidden md:flex items-center">
                  <div className={styles.buttonsContainer}>
                    {!currentUser?.attributes ? (
                      <>
                        <button
                          onClick={() => store.userStore.setLoginModal(true)}
                          className={`btn-style outline-none focus:outline-none cursor-pointer py-1 bg-white rounded-sm flex items-center`}>
                          <span
                            className={`btn-style text-md text-primary-dark font-inter-semibold mx-0 hover:text-pink-purple`}>
                            LOG IN
                          </span>
                        </button>
                      </>
                    ) : (
                      <div className="flex">
                        <LoggedInAvatar
                          onClick={() => {
                            setTopRightDrawer(true);
                          }}
                          currentUser={currentUser}
                          isAdmin={isAdmin || isTeamAdmin}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <Transition appear show={isTopRightDrawerOpen} as={Fragment}>
              <Dialog
                as="div"
                unmount={false}
                onClose={() => {
                  setTopRightDrawer(false);
                }}
                className="relative z-[90]">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-out duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0">
                  <div
                    onClick={() => setTopRightDrawer(false)}
                    className="fixed inset-0 bg-black bg-opacity-25"
                  />
                </Transition.Child>

                <div className="fixed sm:top-[65px] sm:right-[65px]">
                  <Transition.Child
                    as={Fragment}
                    enter="transition ease-in-out duration-300 transform"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transition ease-out-in duration-300 transform"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full">
                    <div className="flex w-full">
                      <div className={`z-[60] flex flex-col bg-white rounded-xl w-full shadow-xl`}>
                        <Dialog.Panel className="overflow-scroll w-full">
                          <div className="flex flex-col justify-between bg-white rounded-xl">
                            <div className="flex flex-col items-start">
                              {(isAdmin || isTeamAdmin) && (
                                <a
                                  className="px-8 pt-4 pb-2 hover:bg-slate-50 hover:rounded-t-xl text-md btn-style w-full cursor-pointer"
                                  href={process.env.NEXT_PUBLIC_APP_ADMIN_PANEL_LINK}
                                  onClick={handleAdminLogin}>
                                  <Image src={SettingsIcon} alt="settings-icon" />
                                  <span className="ml-4">Go to admin panel</span>
                                </a>
                              )}

                              {isUncateringUser && (
                                <a
                                  className="px-8 pt-4 pb-2 hover:bg-slate-50 hover:rounded-t-xl text-md btn-style w-full cursor-pointer"
                                  href={process?.env?.NEXT_PUBLIC_APP_UNCATERING_APP_URL}
                                  onClick={handleUserAccountLogin}>
                                  <Image src={UserIcon} alt="user-icon" />
                                  <span className="ml-4">Switch to user account</span>
                                </a>
                              )}

                              <a
                                // className="px-8 pt-2 pb-2 hover:bg-slate-50 hover:rounded-b-xl text-md btn-style w-full cursor-pointer"
                                className="px-8 pt-2 pb-2 hover:bg-slate-50 hover:rounded-t-xl hover:rounded-b-xl text-md btn-style w-full cursor-pointer"
                                onClick={() => onPressLogout(true)}>
                                <Image src={LogoutIcon} alt="logout-icon" />
                                <span className="ml-4">Logout</span>
                              </a>
                            </div>
                          </div>
                        </Dialog.Panel>
                      </div>
                    </div>
                  </Transition.Child>
                </div>
              </Dialog>
            </Transition>

            {/* Tablet dropdown menu */}
            <Transition appear show={isDrawerOpen} as={Fragment}>
              <Dialog
                as="div"
                unmount={false}
                onClose={() => {}}
                className="relative z-[60] md:hidden">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-out duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0">
                  <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 sm:inset-auto sm:bottom-0 sm:top-0 sm:right-0 flex w-full sm:w-3/4 justify-end h-screen">
                  <Transition.Child
                    as={Fragment}
                    enter="transition ease-in-out duration-300 transform"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transition ease-out-in duration-300 transform"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full">
                    <div className="flex w-full justify-end h-screen">
                      <Dialog.Overlay className="z-[60] fixed inset-0 bg-black bg-opacity-30" />

                      <div className="z-[60] flex flex-col h-full w-full bg-white px-6 py-4 text-left shadow-xl">
                        {/* Close Button */}
                        <div className="flex justify-end mb-4">
                          <button
                            type="button"
                            onClick={() => setDrawer(!isDrawerOpen)}
                            className="btn-style bg-white p-3">
                            <FontAwesomeIcon
                              style={{ width: 16, height: 26 }}
                              className="ml-2"
                              icon={faXmark}
                              color="#DEE2E6"
                              size="2x"
                            />
                          </button>
                        </div>

                        {/* Main Content Area */}
                        <div className="flex flex-col flex-grow mb-4">
                          <div className="flex flex-col mb-4">
                            {(isAdmin || isTeamAdmin) && (
                              <a
                                className="px-4 pt-8 pb-2 text-md btn-style w-full cursor-pointer"
                                href={process.env.NEXT_PUBLIC_APP_ADMIN_PANEL_LINK}
                                onClick={handleAdminLogin}>
                                <Image src={SettingsIcon} alt="settings-icon" />
                                <span className="ml-4">Go to admin panel</span>
                              </a>
                            )}

                            {isUncateringUser && (
                              <a
                                className="px-4 pt-4 pb-2 text-md btn-style w-full cursor-pointer"
                                href={process?.env?.NEXT_PUBLIC_APP_UNCATERING_APP_URL}
                                onClick={handleUserAccountLogin}>
                                <Image src={UserIcon} alt="user-icon" />
                                <span className="ml-4">Switch to user account</span>
                              </a>
                            )}
                          </div>

                          {router?.route !== '/' && (
                            <button
                              onClick={() => {
                                setDrawer(false);
                                setTimeout(() => {
                                  router?.push('/');
                                }, 1000);
                              }}
                              className="btn-style cursor-pointer flex bg-primary-dark hover:bg-hh-accent-dark rounded-md p-4 max-w-[200px]">
                              <div className="flex items-center">
                                <FontAwesomeIcon
                                  icon={faAdd}
                                  size="1x"
                                  className="icon text-white"
                                />
                                <span className="text-white text-md font-inter-medium ml-3">
                                  Place new order
                                </span>
                              </div>
                            </button>
                          )}
                        </div>

                        {/* Footer Area with LOG OUT Button */}
                        <div className="flex flex-col mb-4">
                          {!currentUser ? (
                            <button
                              onClick={onLoginPressMobile}
                              className="bg-primary-dark btn-style hover:bg-pink-purple px-4 text-white rounded-lg py-2 w-full">
                              <span className="mx-0 text-xs">LOG IN</span>
                            </button>
                          ) : (
                            <button
                              onClick={() => onPressLogout(true)}
                              className="bg-primary-dark btn-style hover:bg-pink-purple px-4 text-white rounded-lg py-2 w-full">
                              <span className="mx-0 text-xs">LOG OUT</span>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </Transition.Child>
                </div>
              </Dialog>
            </Transition>
          </>
        )}
      </Disclosure>

      {logoutAlert && (
        <ConfirmationModal
          visible={logoutAlert}
          setModal={setLogoutAlert}
          title={'Are you sure you want to logout?'}
          body={''}
          rightButtonText={'Logout'}
          leftButtonText={'Cancel'}
          onClickLeftButton={onClickCancel}
          onClickRightButton={logout}
        />
      )}
    </>
  );
}

export default observer(SimpleLayout);
