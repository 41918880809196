import { makeAutoObservable, action } from 'mobx';
import api from '../client/api';
import qs from 'qs';
import { makePersistable } from 'mobx-persist-store';
import { client } from '../client';
import { nextLocalStorage } from '../utils/helpers/localStorage';
import { alertHandler } from 'utils/middlewares/alertHandler';
import moment from 'moment';
class RestaurantStore {
  restaurants = [];
  activeRestaurant = null;

  tagsFilterModal = false;

  activeMenuItemTagsLength = 0;

  orderDeadline = null;
  orderDeadlineTime = null;

  constructor(rootStore) {
    this.userStore = rootStore.UserStore;

    makePersistable(this, {
      name: 'RestaurantStore',
      properties: ['restaurants', 'activeRestaurant', 'orderDeadline', 'orderDeadlineTime'],
      storage: nextLocalStorage(),
    });
    makeAutoObservable(this);
  }

  async setTagsFilterModal(value) {
    this.tagsFilterModal = value;
  }

  async setActiveRestaurant(activeRestaurant) {
    this.activeRestaurant = activeRestaurant;
  }

  async setActiveTagsLength(length) {
    this.activeMenuItemTagsLength = length;
  }

  async getRestaurantDetails(id, params) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.menus()}/${id}?${paramsString}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          return data;
        }),
        action('fetchError', error => {
          this.userStore.setErrorAlert(true, alertHandler(error.response));
          return error;
        })
      );
  }

  async getMenuItemOptions(id) {
    return client()
      .get(`${api.menuItems()}/${id}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          return data;
        }),
        action('fetchError', error => {
          this.userStore.setErrorAlert(true, alertHandler(error.response));
          return error;
        })
      );
  }

  async validateAddress(params) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.addressValidation()}/?${paramsString}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          return data;
        }),
        action('fetchError', error => {
          this.userStore.setErrorAlert(true, alertHandler(error.response));
          return error;
        })
      );
  }

  async getMenuPreview(id, payload) {
    let paramsString = qs.stringify(payload);

    return client()
      .get(`${api.previewMenu(id)}/?${paramsString}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          return data;
        }),
        action('fetchError', error => {
          this.userStore.setErrorAlert(true, alertHandler(error.response));
          return error;
        })
      );
  }

  async setOrderDeadline(value) {
    this.orderDeadline = value;
  }

  async setOrderDeadlineTime(value) {
    this.orderDeadlineTime = value;
  }

  async fetchOrderDeadline(menuId, params) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.groupOrderDeadline()}/${menuId}?${paramsString}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          this.setOrderDeadline(
            moment.utc(data?.order_deadline).format('dddd, MMMM Do, YYYY h:mm A')
          );
          this.setOrderDeadlineTime(data?.order_deadline);
          return data;
        }),
        action('fetchError', error => {
          this.userStore.setErrorAlert(true, alertHandler(error.response));
          return error;
        })
      );
  }
}

export default RestaurantStore;
