import { toJS } from 'mobx';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { StoreContext } from 'pages/_app';
import { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react';

const FreshChatComponent = dynamic(() => import('react-freshchat'), {
  ssr: true,
});

const FreshChat = () => {
  const store = useContext(StoreContext);
  const userStore = toJS(store?.userStore);
  const [isFreshChatLoaded, loadFreshChat] = useState(false);
  let { currentUser, isFreshChatOpen } = userStore;
  const router = useRouter();

  useEffect(() => {
    const timeout = null;

    timeout = setTimeout(() => {
      loadFreshChat(true);
    }, 4000);

    () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    if (isFreshChatOpen && window?.fcWidget) {
      window?.fcWidget?.open();
    }
  }, [isFreshChatOpen]);

  useEffect(() => {
    if (window.fcWidget) {
      const handleWidgetClosed = () => {
        store.userStore.setFreshChatOpen(false);
      };

      window.fcWidget.on('widget:closed', handleWidgetClosed);

      return () => {
        window.fcWidget.off('widget:closed', handleWidgetClosed);
      };
    }
  }, []);

  return (
    <>
      {(isFreshChatLoaded || isFreshChatOpen) && (
        <FreshChatComponent
          token={process.env.NEXT_PUBLIC_FRESH_CHAT_TOKEN}
          firstName={currentUser?.attributes?.first_name}
          lastName={currentUser?.attributes?.last_name}
          externalId={currentUser?.attributes?.email}
          email={currentUser?.attributes?.email}
          config={{
            headerProperty: {
              direction: 'rtr',
            },
          }}
        />
      )}
    </>
  );
};

export default observer(FreshChat);
