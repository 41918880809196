import { faComment, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import EmbedHTML from 'components/Shared/EmbedHTML';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { StoreContext } from 'pages/_app';
import { useContext, useEffect, useState } from 'react';
import { ORDER_ITEM_OPTIONS_COUNT } from 'utils/constants/common';
import { hasMinimumTwo } from 'utils/helpers/array';
import { priceFormatter } from 'utils/helpers/validation';

function GroupOrderCartItem({
  cartItem,
  setRemoveItemAlert,
  setAttachLabelAlert,
  setEditItem,
  checkout,
  moreThanOneItemInCategory,
}) {
  const [itemQuantity, setItemQuantity] = useState(cartItem?.attributes?.quantity);
  const [optionsShown, setOptionsShown] = useState(ORDER_ITEM_OPTIONS_COUNT);
  const store = useContext(StoreContext);
  const groupOrderStore = toJS(store?.groupOrderStore);

  let { orderDetails, attendeeId, guestUnCheckedOutOrderId } = groupOrderStore;

  const cartItemSpecialInstructions = cartItem?.attributes?.special_instructions;

  useEffect(() => {
    setItemQuantity(cartItem?.attributes?.quantity);
  }, [cartItem?.attributes?.quantity]);

  const setQuantity = value => {
    let tempQuan = itemQuantity;

    if (value == 'decrement') {
      tempQuan = tempQuan - 1;
      setItemQuantity(tempQuan);
    } else {
      tempQuan = tempQuan + 1;
      setItemQuantity(tempQuan);
    }

    updateItem(tempQuan);
  };

  const updateItem = quantity => {
    store?.groupOrderStore
      ?.updateGroupOrderItem(
        cartItem?.id,
        {
          order_items: {
            quantity,
            // menu_item_id: cartItem?.attributes?.menu_item?.id,
            // special_instructions: cartItem?.attributes?.special_instructions,
            // menu_category_id: cartItem?.attributes?.menu_category_id,
            order_item_options_attributes: cartItem?.attributes?.order_item_options?.map(option => {
              return {
                option_id: option?.attributes?.option_id,
                option_group_id: option?.attributes?.option_category_id,
              };
            }),
          },
        },
        {
          uncheckedout_order_id: guestUnCheckedOutOrderId,
          slug: orderDetails?.attributes?.invitation_link,
        }
      )
      .then(() =>
        store?.groupOrderStore?.getAllGroupOrderCartItems({
          slug: orderDetails?.attributes?.invitation_link,
          uncheckedout_order_id: guestUnCheckedOutOrderId,
        })
      );
  };

  const updateQuantity = e => {
    const itemQuantity = e.target.value;

    setItemQuantity(itemQuantity);

    if (itemQuantity > 0) {
      let quantity = itemQuantity > 0 ? itemQuantity : 1;
      updateItem(quantity);
    }
  };

  return (
    <div
      className={classNames(`flex flex-col last:border-b-0 mb-2 md:mb-0`, {
        'border-b pb-4 border-border-gray': checkout || moreThanOneItemInCategory,
        'border-b-0': !moreThanOneItemInCategory,
      })}>
      <div key={cartItem?.id} className="mb-3">
        <div className="my-3">
          <div className="flex justify-between">
            <span className="text-md text-primary-black font-inter-medium w-3/5">
              <EmbedHTML
                text={
                  checkout
                    ? `<div class="flex"><p class="mr-[5px]">${cartItem?.attributes?.quantity}x</p> ${cartItem?.attributes?.menu_item?.display_name_with_html}</div>`
                    : cartItem?.attributes?.menu_item?.display_name_with_html
                }
                className="text-primary-black"
              />
            </span>

            <div className="flex flex-col w-2/5">
              <span
                className="text-primary-black font-inter-medium text-right"
                style={{ fontSize: 17 }}>
                {priceFormatter(cartItem?.attributes?.total_price)}
              </span>
            </div>
          </div>

          {cartItem?.attributes?.order_item_options
            ?.slice(0, optionsShown)
            ?.map((option, index) => (
              <div className="flex" key={option.id}>
                <span className="d-row text-sm">
                  <EmbedHTML
                    isRow
                    text={option?.attributes?.option_group_display_name_with_html}
                    append={': '}
                    appendClass="mr-1"
                    className="text-primary-black"
                  />
                  <EmbedHTML
                    text={option?.attributes?.option_display_name_with_html}
                    className="text-primary-black"
                  />
                </span>
              </div>
            ))}

          {hasMinimumTwo(cartItem?.attributes?.order_item_options) &&
            optionsShown == ORDER_ITEM_OPTIONS_COUNT && (
              <button
                className="text-sm underline btn-style"
                onClick={() => setOptionsShown(cartItem.attributes.order_item_options.length)}>
                +{cartItem?.attributes?.order_item_options.length - 2} Show all
              </button>
            )}
        </div>

        {(cartItemSpecialInstructions || cartItem?.attributes?.notes) && (
          <div className="flex flex-col bg-background p-2 rounded-md">
            {cartItemSpecialInstructions && (
              <div className="flex items-center ">
                <div className="flex" style={{ width: 12, height: 12 }}>
                  <FontAwesomeIcon icon={faComment} size="1x" className="small-icon" />
                </div>

                <span className="text-sm ml-2">
                  Instructions:{' '}
                  <span className="font-inter-medium">{cartItemSpecialInstructions}</span>
                </span>
              </div>
            )}

            {cartItem?.attributes?.notes && (
              <div className="flex items-center">
                <div className="flex" style={{ width: 12, height: 12 }}>
                  <FontAwesomeIcon icon={faUser} size="1x" className="small-icon" />
                </div>

                <span className="text-sm ml-2">
                  Name attached:{' '}
                  <span className="font-inter-medium">{cartItem?.attributes?.notes}</span>
                </span>
              </div>
            )}
          </div>
        )}
        {!checkout && (
          <div className="flex justify-between items-center mt-3">
            <div className="flex">
              <div
                onClick={() => setEditItem(cartItem)}
                className="flex items-center cursor-pointer">
                <span className="text-sm font-inter-medium">Edit</span>
              </div>

              <div
                className="flex items-center cursor-pointer ml-2"
                onClick={() => setRemoveItemAlert(cartItem.id)}>
                <span className="text-sm font-inter-medium">Remove</span>
              </div>
            </div>

            <div className="flex items-center justify-center border border-border-gray rounded-3xl px-2 py-[1px]">
              <div
                className="py-1 cursor-pointer px-1"
                onClick={() => {
                  if (itemQuantity > 1) setQuantity('decrement');
                }}>
                <div
                  className={classNames('mr-2 bg-primary-black w-3 h-0.5', {
                    'bg-secondary-gray': itemQuantity == 1 || itemQuantity == '',
                  })}
                />
              </div>

              <input
                className="px-2 w-8 text-black-light font-inter-medium text-center text-sm outline-none"
                type="number"
                value={itemQuantity}
                onChange={updateQuantity}
              />

              <div className="py-1 cursor-pointer" onClick={() => setQuantity('increment')}>
                <span
                  className="ml-2 text-2xl text-primary-black font-inter-light"
                  style={{ lineHeight: 0.8 }}>
                  +
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default observer(GroupOrderCartItem);
